import React from "react";
import { Link } from "react-router-dom";
import { Container, Figure } from "react-bootstrap";
import KapcsolatKep from "../kepek/contact-us.png";
import FoodpandaKep from "../kepek/foodpanda-logo.jpg";
import WoltKep from "../kepek/wolt-logo.jpeg";

const JobbOldalsav = () => {
  return (
    <Container fluid>
      <h2>Keress minket az alábbi helyeken!</h2>
      <hr />
      <Link to="/kapcsolat">
        <Figure>
          <Figure.Image
            width="100%"
            height="auto"
            alt="Lépj velünk kapcsolatba!"
            src={KapcsolatKep}
          />
          <Figure.Caption className="text-center">
            Lépj velünk kapcsolatba!
          </Figure.Caption>
        </Figure>
      </Link>
      <a
        href="https://www.foodpanda.hu/restaurant/r1hd/toscana"
        target="_blank"
        rel="noreferrer"
      >
        <Figure>
          <Figure.Image
            width="100%"
            height="auto"
            alt="Keress minket a Foodpanán!"
            src={FoodpandaKep}
          />
          <Figure.Caption className="text-center">
            Keress minket a Foodpanán!
          </Figure.Caption>
        </Figure>
      </a>
      <a
        href="https://wolt.com/hu/hun/szombathely/restaurant/toscana"
        target="_blank"
        rel="noreferrer"
      >
        <Figure>
          <Figure.Image
            width="100%"
            height="auto"
            alt="Keress minket a Wolton!"
            src={WoltKep}
          />
          <Figure.Caption className="text-center">
            Keress minket a Wolton!
          </Figure.Caption>
        </Figure>
      </a>
    </Container>
  );
};

export default JobbOldalsav;

import React from "react";
import { Container, Col, Row } from "react-bootstrap";
import Figure from "react-bootstrap/Figure";

const EtlapblokkTartalom = () => {
  const etlap1 =
    process.env.PUBLIC_URL + "kepek/Toscana_szoro_kiszallitas_202305_A4-1.jpg";
  const etlap2 =
    process.env.PUBLIC_URL + "kepek/Toscana_szoro_kiszallitas_202305_A4-2.jpg";
  return (
    <Container>
      <Row>
        <Col md={12} lg={6}>
          <Figure>
            <a href={etlap1} target="_blank" rel="noreferrer">
              <Figure.Image src={etlap1} />
            </a>
          </Figure>
        </Col>
        <Col md={12} lg={6}>
          <Figure>
            <a href={etlap2} target="_blank" rel="noreferrer">
              <Figure.Image src={etlap2} />
            </a>
          </Figure>
        </Col>
      </Row>
    </Container>
  );
};

export default EtlapblokkTartalom;

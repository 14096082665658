import React from "react";
import { Container, Nav, Navbar } from "react-bootstrap";
import toscanaLogo from "../kepek/toscana-etterem-logo-2021-470x110_.png";
import fejlecHatter from "../kepek/toscana-1950x500-alap.jpg";
import "../style.css";

const Fejlec = () => {
  return (
    <Navbar
      sticky="top"
      expand="lg"
      variant="light"
      bg="light"
      style={{ backgroundImage: `url(${fejlecHatter})` }}
    >
      <Container fluid>
        <Navbar.Brand href="/">
          <img
            alt="Toscana logo"
            title="Toscana logo"
            src={toscanaLogo}
            height="80"
            className="d-inline-block align-top"
          />{" "}
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto nav-menu">
            <Nav.Link href="/">Kezdőlap</Nav.Link>
            <Nav.Link href="etlap">Étlap</Nav.Link>
            <Nav.Link href="kapcsolat">Kapcsolat</Nav.Link>
            <Nav.Link
              href="https://www.foodpanda.hu/restaurant/r1hd/toscana"
              target="_blank"
            >
              Foodpanda
            </Nav.Link>
            <Nav.Link
              href="https://wolt.com/hu/hun/szombathely/restaurant/toscana"
              target="_blank"
            >
              Wolt
            </Nav.Link>
            <Nav.Link
              href="https://www.facebook.com/toscanaszombathely"
              target="_blank"
            >
              Facebook
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
      <Container fluid className="nav-menu">
        Cím: 9700 Szombathely, Szarka Zoltán utca 3.
        <br />
        Telefon: (70) 708 45 59
        <br />
        E-mail: etterem.toscana@gmail.com
      </Container>
    </Navbar>
  );
};

export default Fejlec;

import React from "react";
import { Container, Col, Row } from "react-bootstrap";
import KapcsolatModul from "./KapcsolatModul";

const KapcsolatTartalom = () => {
  return (
    <Container>
      <Row>
        <Col>
          <h3>Kapcsolat</h3>
          <hr />
          <p>
            Cím: 9700 Szombathely, Szarka Zoltán utca 3.
            <br />
            Telefon: (70) 708 45 59
            <br />
            E-mail: etterem.toscana@gmail.com
          </p>
        </Col>
      </Row>
      <KapcsolatModul />
    </Container>
  );
};

export default KapcsolatTartalom;

import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Adatkezeles from "./oldalak/Adatkezeles";
import Cookie from "./oldalak/Cookie";
import Etlap from "./oldalak/Etlap";
import Impresszum from "./oldalak/Impresszum";
import Kapcsolat from "./oldalak/Kapcsolat";
import Kezdolap from "./oldalak/Kezdolap";
import Layout from "./oldalak/Layout";
import NoPage from "./oldalak/NoPage";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />} />
        <Route index element={<Kezdolap />} />
        <Route path="etlap" element={<Etlap />} />
        <Route path="kapcsolat" element={<Kapcsolat />} />
        <Route path="adatkezeles" element={<Adatkezeles />} />
        <Route path="cookie" element={<Cookie />} />
        <Route path="impresszum" element={<Impresszum />} />
        <Route path="*" element={<NoPage />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;

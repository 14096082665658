import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import * as Icon from "react-bootstrap-icons";

const KapcsolatModul = () => {
  return (
    <Container>
      <Row>
        <Col md={12} lg={6} className="bg-success text-white pt-3">
          <Icon.Telephone size="50" />
          <span className="fs-3 ms-3">Telefon</span>
          <p className="fs-5 text-center">(70) 708 45 59</p>
        </Col>
        <Col md={12} lg={6} className="bg-warning text-white pt-3">
          <Icon.EnvelopeAt size="50" />
          <span className="fs-3 ms-3">E-mail</span>
          <p className="fs-5 text-center">etterem.toscana@gmail.com</p>
        </Col>
      </Row>
      <Row>
        <Col md={12} lg={6} className="bg-primary text-white pt-3">
          <Icon.GeoAlt size="50" />
          <span className="fs-3 ms-3">Cím</span>
          <p className="fs-5 text-center">
            9700 Szombathely
            <br />
            Szarka Zoltán utca 3.
          </p>
        </Col>
        <Col md={12} lg={6} className="bg-danger text-white pt-3">
          <Icon.Clock size="50" />
          <span className="fs-3 ms-3">Nyitvatartás</span>
          <p className="fs-5 text-center">
            Hétfő-Csütörtök 11:00 - 20:45
            <br />
            Péntek-Szombat 10:00 - 20:45
            <br />
            Vasárnap ZÁRVA
          </p>
        </Col>
      </Row>
    </Container>
  );
};

export default KapcsolatModul;

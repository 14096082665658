import React from "react";
import { Container } from "react-bootstrap";

const AdatkezelesTartalom = () => {
  return (
    <Container>
      <h1>Adatkezelési tájékoztató</h1>
      <p>Az Ön által megadott személyes adatokat kezeli:</p>
      <p>
        <strong>ARRABONA IMMOBILIEN Kft.</strong>
        <br />
        Székhely: 9025 Győr, Bécsi út 14.
        <br />
        Cégjegyzékszám: 08-09-024851
        <br />
        Nyilvántartásba bejegyző bíróság: CX Törvényszék Cégbírósága{" "}
      </p>
      <p>
        a továbbiakban: <strong>Adatkezelő</strong>.
      </p>
      <p>
        Kérjük, figyelmesen olvassa el jelen adatkezelési tájékoztatónkat (a
        továbbiakban: <strong>Tájékoztató</strong>), melyben személyes adatai
        kezelését érintő gyakorlatunkat ismertetjük az Általános Adatvédelmi
        Rendelet (a GDPR, azaz az Európai Parlament és Tanács 2016/679.
        rendelete) szerint. Ez a Tájékoztató az Adatkezelő szolgáltatásait
        fogyasztóként igénybe vevő személyekre (<strong>Ön</strong>) vonatkozik.
        Bemutatja, hogy az Adatkezelő hogyan gyűjti, használja fel és osztja meg
        bizonyos esetekben harmadik felekkel az Ön személyes adatait, továbbá
        információval szolgál az Ön adatkezeléssel összefüggő „érintetti”
        jogairól.
      </p>
      <p>
        Az Adatkezelő (
        <a href="/impresszum/" data-type="URL" data-id="/impresszum/">
          Impresszum
        </a>
        ) elkötelezett e honlapot felkereső egyének személyes adatainak és
        magánéletének védelme iránt. Jelen tájékoztató és nyilatkozat
        mindenekelőtt az ügyfelek és hírlevél-feliratkozók személyes adatainak
        interneten keresztül történő bizalmas kezeléséről és védelméről szól,
        mindazonáltal ide tartoznak az oldal látogatói is. Összefoglaljuk, hogy
        a tulajdonos milyen Önre vonatkozó adatokat hogyan gyűjthet és
        használhat fel, illetve milyen módon használhatják fel. Az adatok
        illetéktelen személyek részére nem kerülnek átadásra és ezen
        tájékoztatóban meghatározott formában kerülnek felhasználásra.
      </p>
      <p>
        A szerződésekben egyedi esetekben az adatkezelés célja módosulhat, mely
        az adott szerződésben rögzített feltételekkel történik.
      </p>
      <p>
        A tájékoztató fontos információt nyújt Önnek személyes adatai védelméről
        és ehhez fűződő jogairól. Ha a felhasználó nem fogadja el ezen
        feltételeket, jogában áll a honlap használatát felfüggeszteni és
        személyes adat megadása nélkül a böngészést befejezni.
      </p>
      <h3>
        <strong>ADATKEZELŐ FELELŐSSÉGE ÉS ELÉRHETŐSÉGE</strong>
      </h3>
      <p>
        <strong>Adatkezeléssel kapcsolatos információk</strong>
      </p>
      <p>
        Jelen Tájékoztatóban ismertetett adatkezelések tekintetében{" "}
        <strong>ARRABONA IMMOBILIEN Kft.</strong> az adatkezelő (a továbbiakban:{" "}
        <strong>Adatkezelő</strong>).
      </p>
      <p>
        Adatvédelmi eljárásainkat érintő észrevétel, kérdés vagy panasz esetén
        az alábbi elérhetőségeken vagyunk elérhetőek:{" "}
        <strong>ARRABONA IMMOBILIEN Kft.</strong>,{" "}
        <strong>9025 Győr, Bécsi út 14.</strong> vagy írjon az
        etterem.toscana@gmail.com e-mail címre.
      </p>
      <figure class="wp-block-table">
        <table>
          <tbody>
            <tr>
              <td class="has-text-align-center" data-align="center">
                <strong>Adatkezelő</strong>
              </td>
              <td class="has-text-align-center" data-align="center">
                <strong>Felelősségi körök</strong>
              </td>
            </tr>
            <tr>
              <td class="has-text-align-center" data-align="center">
                <strong>ARRABONA IMMOBILIEN Kft.</strong>{" "}
                <strong>9025 Győr, Bécsi út 14.</strong>
              </td>
              <td class="has-text-align-center" data-align="center">
                Összes tevékenység
              </td>
            </tr>
          </tbody>
        </table>
      </figure>
      <p>
        Választásának megfelelően Ön személyes adatai megadása nélkül is
        jogosult hozzáférni weboldalainkhoz, viszont a böngészéshez
        elengedhetetlen a süti - cookie szabályzat elfogadása,
        hírlevél-feliratkozás esetén pedig az adatvédelmi tájékoztató és
        nyilatkozat jóváhagyása. Személyes adat az összes információ, amely
        közvetett vagy közvetlen módon, azonosító szám szerint vagy az ehhez
        tartozó adatok alapján azonosítható természetes személyre vonatkozik.
        Abban az esetben, hogyha az oldal Tulajdonosa az Ön személyes adatait
        gyűjti, az adatgyűjtés átlátható és bizalmas adatkezeléssel történik.
      </p>
      <p>
        Az Ön személyes adatai olyan információk, melyek segítségével az Ön
        személye beazonosítható, például: vezetéknév, utónév, állampolgárság,
        telefonszám, postacím, e-mail cím. Az Ön személyes adatait önkéntesen
        megadott módon, a tulajdonos az oldalon történő böngészéskor gyűjti,
        megjelölve minden esetben a cél eléréséhez szükségszerűen megadandó
        adatokat. Ha nem kívánja megadni ezen adatokat, akkor nem férhet hozzá a
        honlap egyes funkcióihoz, szolgáltatásaihoz. Az opcionálisan megadandó
        adatok gyűjtése a felhasználói igények megismerését, a honlap és
        szolgáltatások fejlesztését szolgálja.
      </p>
      <p>
        A Tulajdonos minden intézkedést megtesz, hogy az általa kezelt személyes
        adatok pontosak, naprakészek legyenek.
      </p>
      <p>
        <strong>Az alábbi személyes adatok kezelése történik:</strong>
      </p>
      <ul>
        <li>
          Vezetéknév, keresztnév, cégnév és kapcsolattartó neve (cég esetén)
        </li>
        <li>
          Székhely címe (cég esetén), lakcím, levelezési cím, szállítási cím
        </li>
        <li>E-mail cím</li>
        <li>Telefonszám</li>
        <li>
          Rendszer információk (IP-cím, rendszer verzió, felbontás, statisztika
          a honlapon megtekintett oldalakról, böngészési szokások, viselkedési
          mintázat)
        </li>
      </ul>
      <h3>
        <strong>Adatkezelés időtartama:</strong>
      </h3>
      <ul>
        <li>
          Direkt marketing célú hozzájárulások tekintetében a felhasználó
          hozzájárulásának visszavonásáig
        </li>
        <li>
          Profiladatok tekintetében az utolsó bejelentkezéstől számított 4 év
        </li>
        <li>
          Vásárlások adatai tekintetében a Számv. tv. 169. § (2) bekezdése
          alapján 8 év
        </li>
      </ul>
      <h3>
        <strong>Adattovábbítás feltételei</strong>
      </h3>
      <p>
        Az Adatkezelő az általa kezelt adatokat - a szükséges mértékben -
        továbbíthatja a következő területeken tevékenykedő, általa kijelölt
        személyek, valamint társaságok részére:
      </p>
      <ul>
        <li>Adatfeldolgozás</li>
        <li>Jogi képviselet</li>
        <li>Jogviták kezelésére jogszabály alapján jogosult szervek</li>
        <li>Kézbesítés</li>
        <li>Könyvelés</li>
        <li>Követeléskezelés</li>
        <li>Marketing</li>
        <li>Számlázás</li>
      </ul>
      <h3>
        <strong>Adatfeldolgozók</strong>
      </h3>
      <p>
        Webáruházi vásárláskor az Adatkezelő (a Felhasználó által megjelölt
        szállítási mód alapján) a Felhasználó nevét, szállítási címét
        (Futárszolgálat esetén e-mail címét és telefonszámát - a kiszállítás
        időpontjának egyeztetése érdekében), továbbá a termék beszedendő
        (esetenként előre kiegyenlített) vételárával kapcsolatos információt
        átadja a kiszállítást végző cég számára, a termék Felhasználó részére
        történő kézbesítése céljából.
      </p>
      <p>Az Adatkezelő által használt futárszolgálatok (adatfeldolgozók):</p>
      <p class="has-text-align-center">
        Nem használunk külső futárszolgálatot, egyéb céget.
      </p>
      <p>
        A Felhasználó elfogadja e hozzájárulás szerint az általa választott
        kiszállító cég saját Általános Szerződési Feltételeit.
      </p>
      <h3>
        <strong>
          Adatfeldolgozást végző vagy adatkezelést érintő cégek, személyek
        </strong>
      </h3>
      <p>
        A tulajdonos, <strong>ARRABONA IMMOBILIEN Kft.</strong> végzi az adatok
        kezelését. A tulajdonoson kívül más cégek is részt vesznek a szerződéses
        ügyfelek adatainak kezelésében, tárolásában.
      </p>
      <h3>
        <strong>Mely cégek kapcsolódnak az adatkezeléshez?</strong>
      </h3>
      <p>A Tulajdonos tevékenysége során az adatkezelésben érintett cégek:</p>
      <ul>
        <li>Tárhelyszolgáltató:</li>
      </ul>
      <p>
        <strong>Név:</strong> ELIN.hu Informatikai Szolgáltató és Tanácsadó Kft.
        <br />
        <strong>Levelezési cím:</strong> 9024 Győr, Déry T. u. 11.
        <br />
        <strong>E-mail cím:</strong>{" "}
        <a href="mailto:info@elin.hu">info@elin.hu</a>
        <br />
        <strong>Telefonszám:</strong> +36 70 297 4811
      </p>
      <ul>
        <li>
          <strong>Google Inc.</strong>: 1600 Amphitheatre Parkway, Mountain
          View, CA 94043, USA,{" "}
          <a href="https://www.google.hu/intl/hu/contact/">
            további elérhetőségek
          </a>
          , levelező rendszer, felhőben történő adat és fájl tárolás, online
          dokumentum kezelés, továbbá a hozzátartozó szolgáltatások: Google
          Drive, Google Docs, Google Search Console, Google Analytics, Google
          AdSense, Google AdWords, YouTube, Blogger, Chrome böngésző támogatása
          -<a href="https://www.facebook.com/about/privacy/update"> </a>Google{" "}
          <a href="https://support.google.com/a/answer/60762?hl=en">
            adatvédelme
          </a>{" "}
          és{" "}
          <a href="https://policies.google.com/privacy?hl=hu">
            adatvédelmi elvei
          </a>
          .
        </li>
        <li>
          <strong>Facebook Ireland Ltd.</strong>: 4 Grand Canal Square, Grand
          Canal Harbour, Dublin 2 Ireland, a Facebook, Instagram, Messenger és
          Facebook által kínált egyéb termékek, funkciók támogatása -
          <a href="https://www.facebook.com/about/privacy/update"> </a>
          <a href="https://www.facebook.com/about/privacy/update">
            adatkezelési szabályzat
          </a>{" "}
          és adatvédelmi tisztviselő
          <a href="https://www.facebook.com/help/contact/540977946302970"> </a>
          <a href="https://www.facebook.com/help/contact/540977946302970">
            elérhetősége
          </a>
        </li>
      </ul>
      <h3>
        <strong>Adattulajdonosi jogok és jogérvényesítés</strong>
      </h3>
      <p>
        Amennyiben nem szeretné személyes adatai kereskedelmi ajánlatok
        közlésére történő felhasználását, joga van mindezt indoklás nélkül
        megtiltani. Törlési kérelmét írásban szükséges megküldenie az Adatkezelő
        részére, e-mailben vagy postai levél formájában, az{" "}
        <a href="/impresszum/" data-type="URL" data-id="/impresszum/">
          Impresszumban
        </a>{" "}
        megjelölt elérhetőségekre.
      </p>
      <h3>
        <strong>
          Kiskorúak és korlátozottan cselekvőképes személyes adatainak védelme
        </strong>
      </h3>
      <p>
        <strong>ARRABONA IMMOBILIEN Kft.</strong> internetes weboldala, valamint
        megjelenéseinek tartalma többnyire nem 13 év alatti kiskorúak részére
        szól. Nem kezelünk és nem gyűjtünk 13 év alatti gyermekekről személyes
        adatokat igazolható szülői vagy gondviselői hozzájárulás nélkül. Szülei
        vagy gondviselői kérhetik a gyermekre vonatkozó adatok kiadását,
        törlését. Ha az Adatkezelő számára az adatkezelés, adatfeldolgozás
        folyamán válik nyilvánvalóvá az életkor, abban az esetben a szülői
        hozzájárulás beszerzése céljából az adatok felhasználhatók.
      </p>
      <p>
        Törvényes képviselő hozzájárulása szükséges cselekvőképtelen és
        korlátozottan cselekvőképes kiskorú személy nyilatkozatához, kivéve a
        mindennapi életben tömegesen előforduló regisztrációt célzó és
        különösebb megfontolást nem igénylő szolgáltatásrészeket.
      </p>
      <h3>
        <strong>Cookie-k (sütik) kezelése</strong>
      </h3>
      <p>
        <strong>
          <em>Mi az a cookie?</em>
        </strong>
      </p>
      <p>
        Az Adatkezelő a honlap látogatása során úgynevezett cookie-kat (sütiket)
        használ. A cookie betűből és számokból álló információcsomag, amit
        honlapunk az Ön böngészőjének küld el&nbsp; azzal a céllal, hogy
        elmentse bizonyos beállításait, megkönnyítse a honlapunk használatát és
        közreműködik abban, hogy néhány releváns, statisztikai jellegű
        információt gyűjtsünk a látogatóinkról.
      </p>
      <p>
        A cookie-k (sütik) egy része nem tartalmaz személyes információkat, és
        nem alkalmas az egyéni felhasználó azonosítására, egy részük azonban
        olyan egyéni azonosítót tartalmaz – egy titkos, véletlenül generált
        számsort – amelyet az Ön eszköze tárol, ezzel az Ön azonosíthatóságát is
        biztosítja. Az egyes cookie-k (sütik) működési időtartamát az egyes
        cookie-k (sütik) vonatkozó leírása tartalmazza.
      </p>
      <p>
        <strong>
          <em>A cookie-k jogszabályi háttere és jogalapja:</em>
        </strong>
      </p>
      <p>
        Az adatkezelés jogalapja a Rendelet 6. cikk (1) bekezdés a) pontja
        alapján az &nbsp;Ön hozzájárulása.
      </p>
      <p>
        <strong>
          <em>A honlap által használt cookie-k főbb jellemzői:</em>
        </strong>
      </p>
      <p>
        <strong>Google Adwords cookie</strong>&nbsp;Amikor valaki meglátogatja
        az oldalunkat, a látogató cookie-azonosítója hozzáadódik a
        remarketinglistához. A Google cookie-kat – például a NID és SID
        cookie-kat – használ a Google-termékekben, így például a Google
        Keresésben látható hirdetések testreszabásához. Az ilyen cookie-kat
        például arra használja, hogy megjegyezze az Ön legutóbbi kereséseit, az
        egyes hirdetők hirdetéseivel vagy a keresési eredményekkel való korábbi
        interakcióit, továbbá a hirdetők webhelyein tett látogatásait. Az
        AdWords konverziókövetés funkciója cookie-kat használ. A hirdetésből
        eredő értékesítések és egyéb konverziók követésére cookie-kat ment a
        felhasználó számítógépére, amikor az adott személy egy hirdetésre
        kattint. A cookie-k néhány gyakori alkalmazási módja: a hirdetések
        kiválasztása annak alapján, hogy mi a releváns az adott felhasználó
        esetén, a kampányok teljesítményéről szóló jelentések tökéletesítése, és
        a felhasználó által már megtekintett hirdetések megjelenítésének
        elkerülése.
      </p>
      <p>
        <strong>Google Analytics cookie:</strong>&nbsp;A Google Analytics a
        Google elemző eszköze, amely abban segít a weboldalak és alkalmazások
        tulajdonosainak, hogy pontosabb képet kapjanak látogatóik
        tevékenységeiről. A szolgáltatás cookie-kat használhat, hogy
        információkat gyűjtsön és jelentést készítsen a weboldal használatára
        vonatkozó statisztikai adatokból anélkül, hogy egyénileg azonosítaná a
        látogatókat a Google számára. A Google Analytics által használt fő
        cookie a „__ga” cookie. A webhelyhasználati statisztikai adatokból
        készülő jelentések mellett a Google Analytics – az előbbiekben
        ismertetett néhány hirdetési cookie-val együtt – felhasználható arra is,
        hogy relevánsabb hirdetéseket jelenítsünk meg a Google-termékekben
        (például a Google Keresésben) és szerte az interneten.
      </p>
      <p>
        <strong>Remarketing cookiek-k:</strong>&nbsp;A korábbi látogatók vagy
        felhasználók számára a Google Display Hálózaton található egyéb
        webhelyeken való böngészés, illetve termékeivel vagy szolgáltatásaival
        kapcsolatos kifejezések keresésekor jelenhetnek meg
      </p>
      <p>
        <strong>A működéshez szigorúan szükséges cookie-k:</strong>&nbsp;Ezek a
        cookie-k a weboldal használatához nélkülözhetetlenek, és lehetővé teszik
        a weboldal alapvető funkcióinak használatát. Ezek hiányába az oldal
        számos funkciója nem lesz elérhető az Ön számára. Ezen típusú cookie-k
        élettartama kizárólag a munkamenet idejére korlátozódik.
      </p>
      <p>
        <strong>
          A felhasználói élmény javítását szolgáló cookie-k:&nbsp;
        </strong>
        Ezek a cookie-k információkat gyűjtenek a felhasználó
        weboldalhasználatáról, például, hogy mely oldalakat látogatja
        leggyakrabban, vagy milyen hibaüzenetet kap a weboldalról. Ezek a
        cookie-k nem gyűjtenek a látogatót azonosító információkat, vagyis
        teljesen általános, névtelen információkkal dolgoznak. Az ezekből nyert
        adatokat a weboldal teljesítményének javítására használjuk. Ezen típusú
        cookie-k élettartama kizárólag a munkamenet idejére korlátozódik.
      </p>
      <p>
        <strong>Munkamenet cookie:</strong>&nbsp;Ezek a sütik a látogató
        tartózkodási helyét, a böngésző nyelvét, a fizetés pénznemét tárolják,
        élettartamuk a böngésző bezárása, vagy maximum 2 óra.
      </p>
      <p>
        <strong>Referer cookiek:</strong>&nbsp;Azt rögzítik, hogy a látogató
        milyen külső oldalról érkezett az oldalra. Élettartamuk a böngésző
        bezárásáig tart.
      </p>
      <p>
        <strong>Utoljára megtekintett termék cookie:</strong>&nbsp;Rögzíti a
        termékeket, amiket utoljára megtekintett a látogató. Élettartamuk 60
        nap.
      </p>
      <p>
        <strong>Utoljára megtekintett kategória cookie:</strong>&nbsp;Rögzítit
        az utoljára megtekintett kategóriát. Élettartama 60 nap.
      </p>
      <p>
        <strong>Ajánlott termékek cookie:</strong>&nbsp;Az „ajánlom
        ismerősömnek” funkciónál az ajánlani kívánt termékek listáját rögzíti.
        Élettartama 60 nap.
      </p>
      <p>
        <strong>Mobil verzió, design cookie:</strong>&nbsp;Érzékeli a látogató
        használt eszközt, és mobilon átvált teljes nézetre. Élettartama 365 nap.
      </p>
      <p>
        <strong>Cookie elfogadás cookie:</strong>&nbsp;Az oldalra érkezéskor a
        figyelmeztető ablakban elfogadja a cookiek tárolásáról szóló
        nyilatkozatot. Élettartama 365 nap.
      </p>
      <p>
        <strong>Kosár cookie:</strong>&nbsp;A kosárba helyezett termékeket
        rögzíti. Élettartama 365 nap.
      </p>
      <p>
        <strong>Intelligens ajánlat cookie:</strong>&nbsp;Intelligens ajánlatok
        megjelenítésének feltételeit rögzíti (pl. volt-e már az oldalon a
        látogató, van-e rendelése). Élettartama 30 nap.
      </p>
      <p>
        <strong>Facebook pixel (Facebook cookie)</strong>&nbsp;A
        Facebook-képpont olyan kód, amelynek a segítségével a honlapon jelentés
        készül a konverziókról, célközönségek állíthatók össze, és az oldal
        tulajdonosa részletes elemzési adatokat kap a látogatók honlap
        használatáról. A Facebook pixel segítségével a weboldal látogatóinak
        személyre szabott ajánlatokat, hirdetéseket jeleníthet meg a Facebook
        felületén. A Facebook adatkezelési szabályzatát itt tanulmányozhatja:
        https://www.facebook.com/privacy/explanation
      </p>
      <p>
        Amennyiben Ön nem fogadja el a cookie-k használatát, akkor bizonyos
        funkciók nem lesznek elérhetőek az Ön számára. A cookie-k törléséről
        bővebb tájékoztatást az alábbi linkeken találhat:
      </p>
      <ul>
        <li>
          Internet Explorer:&nbsp;&nbsp;
          <a
            href="http://windows.microsoft.com/en-us/internet-explorer/delete-manage-cookies#ie=ie-11"
            target="_blank"
            rel="noreferrer noopener"
          >
            http://windows.microsoft.com/en-us/internet-explorer/delete-manage-cookies#ie=ie-11
          </a>
        </li>
        <li>
          Firefox:&nbsp;&nbsp;
          <a
            href="https://support.mozilla.org/en-US/kb/cookies-information-websites-store-on-your-computer"
            target="_blank"
            rel="noreferrer noopener"
          >
            https://support.mozilla.org/en-US/kb/cookies-information-websites-store-on-your-computer
          </a>
        </li>
        <li>
          Mozilla:&nbsp;
          <a
            href="https://support.mozilla.org/hu/kb/weboldalak-altal-elhelyezett-sutik-torlese-szamito"
            target="_blank"
            rel="noreferrer noopener"
          >
            https://support.mozilla.org/hu/kb/weboldalak-altal-elhelyezett-sutik-torlese-szamito
          </a>
        </li>
        <li>
          Safari:&nbsp;
          <a
            href="https://support.apple.com/guide/safari/manage-cookies-and-website-data-sfri11471/mac"
            target="_blank"
            rel="noreferrer noopener"
          >
            https://support.apple.com/guide/safari/manage-cookies-and-website-data-sfri11471/mac
          </a>
        </li>
        <li>
          Chrome:&nbsp;
          <a
            href="https://support.google.com/chrome/answer/95647"
            target="_blank"
            rel="noreferrer noopener"
          >
            https://support.google.com/chrome/answer/95647
          </a>
        </li>
        <li>
          Edge:&nbsp;
          <a
            href="https://support.microsoft.com/hu-hu/help/4027947/microsoft-edge-delete-cookies"
            target="_blank"
            rel="noreferrer noopener"
          >
            https://support.microsoft.com/hu-hu/help/4027947/microsoft-edge-delete-cookies
          </a>
        </li>
      </ul>
      <h3>
        <strong>Fogalmak</strong>
      </h3>
      <ul>
        <li>
          <strong>Adatfeldolgozó: </strong>természetes vagy jogi személy,
          közhatalmi szerv, ügynökség vagy bármely egyéb szerv, amely az
          adatkezelő nevében személyes adatokat kezel
        </li>
        <li>
          <strong>Adatkezelés: </strong>a személyes adatokon vagy
          adatállományokon automatizált vagy nem automatizált módon végzett
          bármely művelet vagy műveletek összessége, például: gyűjtés, rögzítés,
          rendszerezés, tagolás, tárolás, átalakítás, megváltoztatás,
          lekérdezés, betekintés, felhasználás, közlés, továbbítás, terjesztés,
          egyéb módon történő hozzáférhetővé tétel, összehangolás,
          összekapcsolás, korlátozás, törlés, megsemmisítés
        </li>
        <li>
          <strong>Adattovábbítás:</strong> az Adatkezelő által kezelt személyes
          adatok harmadik személyek számára történő hozzáférhetővé tétele
        </li>
        <li>
          <strong>Adatvédelmi incidens: </strong>a biztonság sérülése, amely a
          továbbított, tárolt vagy más módon kezelt személyes adatok véletlen
          vagy jogellenes megsemmisítését, elvesztését, megváltoztatását,
          jogosulatlan közlését vagy az azokhoz való jogosulatlan hozzáférést
          eredményezi
        </li>
        <li>
          <strong>ÁSZF</strong>: a weboldalon közzétett, az Adatkezelő termék-
          és szolgáltatás-értékesítési szabályait rögzítő Általános Szerződési
          Feltételek
        </li>
        <li>
          <strong>Azonosítható természetes személy:</strong> természetes
          személy, aki közvetlen vagy közvetett módon, valamely azonosító,
          például: név, azonosító szám, helymeghatározó adat, online azonosító,
          fizikai, fiziológiai, genetikai, szellemi, gazdasági, kulturális vagy
          szociális azonosságára vonatkozó egy vagy több tényező alapján
          azonosítható
        </li>
        <li>
          <strong>Címzett:</strong> természetes vagy jogi személy, közhatalmi
          szerv, ügynökség vagy bármely egyéb szerv, amellyel a személyes adatot
          közlik, függetlenül attól, hogy harmadik fél-e
        </li>
        <li>
          <strong>Érintett hozzájárulása:</strong> az érintett akaratának
          önkéntes, konkrét, megfelelő tájékoztatáson alapuló, egyértelmű
          kinyilvánítása, amellyel az érintett nyilatkozat vagy a megerősítést
          félreérthetetlenül kifejező cselekedet útján jelzi, hogy beleegyezését
          adja az őt érintő személyes adatok kezeléséhez
        </li>
        <li>
          <strong>Érintett:</strong> bármely információ alapján azonosított vagy
          azonosítható természetes személy
        </li>
        <li>
          <strong>Felhasználók:</strong> az Adatkezelő által üzemeltetett,
          https://toscanaszombathely.hu/ weboldalon és az ott meghatározott
          egyéb címeken elérhető weboldalak látogatói
        </li>
        <li>
          <strong>Harmadik fél:</strong> természetes vagy jogi személy,
          közhatalmi szerv, ügynökség vagy bármely egyéb szerv, amely nem azonos
          az érintettel, az adatkezelővel, az adatfeldolgozóval vagy azokkal a
          személyekkel, akik az adatkezelő vagy adatfeldolgozó közvetlen
          irányítása alatt a személyes adatok kezelésére felhatalmazást kaptak
        </li>
        <li>
          <strong>Hatóság: </strong>Nemzeti Adatvédelmi és Információszabadság
          Hatóság (székhely: 1125 Budapest, Szilágyi Erzsébet fasor 22/c.,
          telefon: 06-1 391-1400, fax: 06-1&nbsp;391-1410, e-mail:
          ugyfelszolgalat@naih.hu, weboldal: naih.hu)
        </li>
        <li>
          <strong>Partner/Ügyfél:</strong> természetes személy, aki az
          Adatkezelővel nem weboldalon köt szerződést termék értékesítésére vagy
          szolgáltatás nyújtására
        </li>
        <li>
          <strong>Regisztráció: </strong>a weboldal felhasználó személyes
          adatainak rögzítése, mellyel felhasználói fiókot hoz létre
        </li>
        <li>
          <strong>Személyes adat:</strong> az érintettre vonatkozó bármely
          információ
        </li>
        <li>
          <strong>Szolgáltatás: </strong>az Adatkezelő által Ügyfelei, Partnerei
          és Felhasználói részére a weboldalon vagy egyéb módon nyújtott, az
          ÁSZF-ben részletezett termékértékesítési és egyéb szolgáltatások
        </li>
        <li>
          <strong>Weboldal:</strong> az Adatkezelő által üzemeltetett,{" "}
          <strong>ARRABONA IMMOBILIEN Kft.</strong> és az ott meghatározott
          egyéb címeken elérhető weboldalai
        </li>
      </ul>
    </Container>
  );
};

export default AdatkezelesTartalom;

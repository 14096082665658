import React, { useState, useEffect } from "react";
import { Container } from "react-bootstrap";
import Carousel from "react-bootstrap/Carousel";

const MenuLapozhato = () => {
  const [images, setImages] = useState([]);
  const apiUrl = "https://toscanaszombathely.hu/api/api.php";

  useEffect(() => {
    fetch(apiUrl, {
      method: "GET",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setImages(data);
        //console.log(data);
      });
  }, []);

  return (
    <Container>
      <Carousel>
        {Object.keys(images).map((image, index) => (
          <Carousel.Item key={index}>
            <a
              href={`/hetimenu/${images[image]}`}
              target="_blank"
              rel="noreferrer"
            >
              <img
                className="d-block w-100"
                src={`/hetimenu/${images[image]}`}
                key={index}
                alt="Heti menü"
              />
            </a>
          </Carousel.Item>
        ))}
      </Carousel>
    </Container>
  );
};

export default MenuLapozhato;

import React from "react";
import Fejlec from "../components/Fejlec";

const NoPage = () => {
  return (
    <>
      <Fejlec />
      <div>404</div>
    </>
  );
};

export default NoPage;
